/*
 *  Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 *
 *  Licensed under the Apache License, Version 2.0 (the "License").
 *  You may not use this file except in compliance with the License.
 *  A copy of the License is located at
 *
 *  http://aws.amazon.com/apache2.0
 *
 *  or in the "license" file accompanying this file. This file is distributed
 *  on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either
 *  express or implied. See the License for the specific language governing
 *  permissions and limitations under the License.
 */
import _ from 'lodash';
import React from 'react';
import { decorate, computed } from 'mobx';
import { observer, inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { Line } from 'react-chartjs-2';
import { Table, Message } from 'semantic-ui-react';

import { isStoreLoading, isStoreError, isStoreReady } from '@amzn/base-ui/dist/models/BaseStore';
import { swallowError, nicePrice, niceDate } from '@amzn/base-ui/dist/helpers/utils';
import ErrorBox from '@amzn/base-ui/dist/parts/helpers/ErrorBox';
import ProgressPlaceHolder from '@amzn/base-ui/dist/parts/helpers/BasicProgressPlaceholder';

// expected props
// - envId (via prop)
// - scEnvironmentCostsStore  (via injection)
class ScEnvironmentCostTable extends React.Component {
  componentDidMount() {
    const costStore = this.getEnvCostStore();
    if (!isStoreReady(costStore) && !isStoreError(costStore)) {
      swallowError(costStore.load());
    }

    costStore.startHeartbeat();
  }

  componentWillUnmount() {
    const costStore = this.getEnvCostStore();
    costStore.stopHeartbeat();
  }

  get costsStore() {
    return this.props.scEnvironmentCostsStore;
  }

  get envId() {
    return this.props.envId;
  }

  getEnvCostStore() {
    const costsStore = this.costsStore;
    const envId = this.envId;
    return costsStore.getScEnvironmentCostStore(envId);
  }

  renderCostGraph() {
    const costStore = this.getEnvCostStore();
    const cost = costStore.scEnvironmentCost;
    const list = cost.list;
    const isEmpty = _.isEmpty(list);
  
    // Prepare data for the graph
    const labels = list.map(item => item.date); // Assuming each item has a 'date' property
    const dataPoints = list.map(item => item.value); // Assuming each item has a 'value' property
    
    console.log('labels', labels);
    console.log('dataPoints', dataPoints);

    const data = {
      labels,
      datasets: [
        {
          label: 'Workspace Cost Over Time',
          data: dataPoints,
          fill: false,
          borderColor: 'rgb(75, 192, 192)',
          tension: 0.1,
        },
      ],
    };
  
    const options = {
      maintainAspectRatio: false,
      scales: {
        y: {
          beginAtZero: true,
          title: {
            display: true,
            text: 'Cost',
          },
        },
        x: {
          title: {
            display: true,
            text: 'Date',
          },
        },
      },
    };
  
    // Render the Line graph
    return (
      <div>
        <Line data={data} options={options} />
      </div>
    );
  }

  render() {
    const store = this.getEnvCostStore();
    let content = null;

    if (isStoreError(store)) {
      content = <ErrorBox error={store.error} className="p0" />;
    } else if (isStoreLoading(store)) {
      content = <ProgressPlaceHolder />;
    } else if (isStoreReady(store)) {
      content = this.renderMain();
    } else {
      content = null;
    }

    return <>{content}</>;
  }

  renderMain() {
    const costStore = this.getEnvCostStore();
    const cost = costStore.scEnvironmentCost;
    const list = cost.list;
    const isEmpty = _.isEmpty(list);
    const renderRow = (index, key, value) => (
      <Table.Row key={index}>
        <Table.Cell width={5}>
          {niceDate(key)}
        </Table.Cell>
        <Table.Cell width={11} className="breakout">
          ${nicePrice(value)} USD
        </Table.Cell>
      </Table.Row>
    );

    return (
      <>
        <Message info>
          <Message.Content>
            Please note that AWS cost information is updated with a 24-hour delay.
          </Message.Content>
        </Message>
        {!isEmpty && (
          <Table definition className="mt3">
            <Table.Body>{_.map([...list].reverse(), (item, index) => renderRow(index, item.date, item.amount))}</Table.Body>
          </Table>
        )}
        {isEmpty && <Message className="mt3" content="None is available" />}
      </>
    );
  }
}

// see https://medium.com/@mweststrate/mobx-4-better-simpler-faster-smaller-c1fbc08008da
decorate(ScEnvironmentCostTable, {
  costsStore: computed,
  envId: computed,
});

export default inject('scEnvironmentCostsStore')(withRouter(observer(ScEnvironmentCostTable)));
