import _ from 'lodash';
import React from 'react';
import { decorate, computed, action, runInAction, observable } from 'mobx';
import { observer, inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { Button, ButtonContent, Table, List, Label, Icon, Accordion } from 'semantic-ui-react';

import { displayError } from '@amzn/base-ui/dist/helpers/notification';

import CopyToClipboard from '../../helpers/CopyToClipboard';
import RdpFile from '../../helpers/RdpFile';

const openWindow = (url, windowFeatures) => {
  return window.open(url, '_blank', windowFeatures);
};

// expected props
// - scEnvironment (via prop)
// - connectionId (via prop)
// - scEnvironmentsStore (via injection)
class ScEnvironmentRdpConnectionRow extends React.Component {
  constructor(props) {
    super(props);
    runInAction(() => {
      // The windowsRdpInfo we get once we ask for the windows rdp info
      // This is an object { password: <string>, networkInterfaces: [ ... ] }
      this.windowsRdpInfo = undefined;
      // A flag to indicate if we are in the process of getting the windows rdp info
      this.processingGetInfo = false;
      // A flag to indicate if we're getting the connection url
      this.processingGetConnection = false;
      // Should the password be shown
      this.showPassword = false;
      this.processingId = undefined;
      this.rdpExpanded = false;
      this.dcvExpanded = false;
    });
  }

  get isAppStreamEnabled() {
    return process.env.REACT_APP_IS_APP_STREAM_ENABLED === 'true';
  }

  get environment() {
    return this.props.scEnvironment;
  }

  get envsStore() {
    return this.props.scEnvironmentsStore;
  }

  getConnectionStore() {
    return this.envsStore.getScEnvConnectionStore(this.environment.id);
  }

  // Returns only the connections that scheme = 'rdp'
  // [ {id, name: <string>(optional), instanceId: <string>, scheme: 'rdp'}, ... ]
  get connections() {
    const connections = this.environment.getConnections(item => item.scheme === 'rdp');

    return connections;
  }

  get connection() {
    const id = this.connectionId;
    const connections = this.connections;

    return _.find(connections, ['id', id]) || {};
  }

  get connectionId() {
    return this.props.connectionId;
  }

  get networkInterfaces() {
    const entries = _.get(this.windowsRdpInfo, 'networkInterfaces');
    if (_.isEmpty(entries)) return [];

    const result = [];
    _.forEach(entries, item => {
      if (item.publicDnsName) result.push({ value: item.publicDnsName, type: 'dns', scope: 'public', info: 'Public' });
      if (item.privateIp) result.push({ value: item.privateIp, type: 'ip', scope: 'private', info: 'Private' });
    });

    return result;
  }

  handleRdpExpanded = () => {
    runInAction(() => {
      this.rdpExpanded = !this.rdpExpanded;
    });
  };

  handleDcvExpanded = () => {
    runInAction(() => {
      this.dcvExpanded = !this.dcvExpanded;
    });
  };

  handleAppStreamConnect = id =>
    action(async () => {
      try {
        runInAction(() => {
          this.processingGetConnection = true;
        });
        const store = this.getConnectionStore();
        const urlObj = await store.createConnectionUrl(id);
        const appStreamUrl = urlObj.url;
        if (appStreamUrl) {
          const newTab = openWindow('about:blank');
          newTab.location = appStreamUrl;
        } else {
          throw Error('AppStream URL was not returned by the API');
        }
        runInAction(() => {
          this.processingGetConnection = false;
          this.processingId = id;
        });
      } catch (error) {
        displayError(error);
      } finally {
        runInAction(() => {
          this.processingId = '';
        });
      }
    });

    handleDcvConnect = (id, useClientProtocol) => action(async () => {
      try {
        runInAction(() => {
          this.processingGetConnection = true;
        });
    
        // Ensure we have the RDP info
        if (!this.windowsRdpInfo) {
          await this.handleGetInfo();
        }
    
        if (this.windowsRdpInfo) {
          const publicNetwork = this.networkInterfaces.find(ni => ni.info === 'Public');
          
          if (publicNetwork) {
            let url = `https://${publicNetwork.value}:8443`;
            
            if (useClientProtocol) {
              url = url.replace('https://', 'dcv://');
            }
            
            // We use noopener and noreferrer for good practices
            openWindow(url, 'noopener,noreferrer');
          } else {
            throw Error('Public DNS not available. Please try RDP instead.');
          }
        } else {
          throw Error('Unable to retrieve RDP information');
        }
    
        runInAction(() => {
          this.processingGetConnection = false;
          this.processingId = id;
        });
      } catch (error) {
        displayError(error);
      } finally {
        runInAction(() => {
          this.processingId = '';
        });
      }
    });

  handleGetInfo = async () => {
    const store = this.getConnectionStore();
    const connectionId = this.connectionId;

    runInAction(() => {
      this.windowsRdpInfo = undefined;
      this.showPassword = false;
      this.processingGetInfo = true;
    });

    try {
      const result = await store.getWindowsRdpInfo(connectionId);
      runInAction(() => {
        this.windowsRdpInfo = result || {};
      });
    } catch (error) {
      displayError(error);
    } finally {
      runInAction(() => {
        this.processingGetInfo = false;
      });
    }
  };

  handleDownloadRdpFile = async () => {
    if (!this.windowsRdpInfo) {
      // If not available, get the password info
      await this.handleGetInfo();
    }

    // Check again in case handleGetInfo failed
    if (!this.windowsRdpInfo) {
      displayError(new Error('Unable to retrieve RDP information'));
      return;
    }

    const publicNetwork = this.networkInterfaces.find(ni => ni.info === 'Public');
    if (!publicNetwork) {
      displayError(new Error('Public DNS not available'));
      return;
    }

    const rdpFileContents = RdpFile({
      publicHostname: publicNetwork.value,
      username: ".\\Administrator",
    });

    if (rdpFileContents) {
      const blob = new Blob([rdpFileContents], { type: 'application/x-rdp' });
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = 'race-workspace.rdp';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    } else {
      displayError(new Error('Unable to generate RDP file'));
    }
  };

  handleOpenDcv = action(async (useClientProtocol) => {
    // Check if the RDP info is already available
    if (!this.windowsRdpInfo) {
      // If not available, get the password info
      await this.handleGetInfo();
    }
  
    // If the password info is available (either from before or just fetched), connect
    if (this.windowsRdpInfo) {
      await this.handleDcvConnect(this.connectionId, useClientProtocol)();
    } else {
      // If we still don't have the info, display an error
      displayError(new Error("Unable to retrieve RDP information"));
    }
  });

  handleOpenDcvClient = action(async () => {
    await this.handleOpenDcv(true);
  });
  
  handleOpenDcvBrowser = action(async () => {
    await this.handleOpenDcv(false);
  });

  toggleShowPassword = () => {
    this.showPassword = !this.showPassword;
  };

  render() {
    const item = this.connection;
    const windowsRdpInfo = this.windowsRdpInfo;
    const processing = this.processingGetInfo;

    const rows = [
      <Table.Row key={item.id}>
        <Table.Cell className="clearfix">
        <Button
            animated="fade"
            data-testid="open-dcv-browser-button"
            floated="right"
            size="mini"
            onClick={this.handleOpenDcvBrowser}
          >
            <ButtonContent visible>DCV (Browser)</ButtonContent>
            <ButtonContent hidden>Open in new tab</ButtonContent>
          </Button>
        <Button
            animated="fade"
            data-testid="open-dcv-client-button"
            floated="right"
            size="mini"
            onClick={this.handleOpenDcvClient}
          >
            <ButtonContent visible>DCV (Client)</ButtonContent>
            <ButtonContent hidden>Open in DCV</ButtonContent>
          </Button>
          <Button
            animated="fade"
            data-testid="download-rdp-button"
            floated="right"
            size="mini"
            onClick={this.handleDownloadRdpFile}
          >
            <ButtonContent visible>RDP File</ButtonContent>
            <ButtonContent hidden>Download</ButtonContent>
          </Button>
          <Button
            data-testid="get-password-button"
            floated="right"
            size="mini"
            primary
            loading={processing}
            onClick={this.handleGetInfo}
          >
            Get Password
          </Button>
          <div className="mt1">{item.name || 'Connect'}</div>
        </Table.Cell>
      </Table.Row>,
    ];

    if (windowsRdpInfo) {
      rows.push(this.renderExpanded());
    }

    return rows;
  }

  renderExpanded() {
    const item = this.connection;
    const windowsRdpInfo = this.windowsRdpInfo;
    const interfaces = this.networkInterfaces;
    const username = '.\\Administrator';
    const password = windowsRdpInfo.password;
    const showPassword = this.showPassword;
    const connectionId = this.connectionId;
    const moreThanOne = _.size(interfaces) > 1;
    const rdpExpanded = this.rdpExpanded;
    const dcvExpanded = this.dcvExpanded;

    return (
      <>
        <Table.Row key={`${item.id}__2`}>
          <Table.Cell className="p3">
            <b>Credentials:</b>
            <List bulleted>
              {!this.isAppStreamEnabled && (
                <List.Item>
                  The public DNS of the instance{' '}
                  {moreThanOne ? ':' : ''}
                  <List>
                    {_.map(interfaces, network => (
                      network.info === 'Public' && (
                        <List.Item key={network.value} className="flex">
                          {this.renderHostLabel(network)}
                          <CopyToClipboard text={network.value} />
                        </List.Item>
                      )
                    ))}
                  </List>
                </List.Item>
              )}
              <List.Item>
                Your username and password:
                <List>
                  <List.Item className="flex">
                    {this.renderUsernameLabel(username)}
                    <CopyToClipboard text={username} />
                  </List.Item>
                  <List.Item className="flex">
                    {this.renderPasswordLabel(password)}
                    <CopyToClipboard text={password} />
                    <Button className="ml2" basic size="mini" onClick={this.toggleShowPassword}>
                      {showPassword ? 'Hide' : 'Show'}
                    </Button>
                  </List.Item>
                </List>
              </List.Item>
            </List>
          </Table.Cell>
        </Table.Row>
        <Table.Row key={`${item.id}__2`}>
          <Table.Cell className="p3">
            <Accordion className="mt2">
            <Accordion.Title active={rdpExpanded} index={0} onClick={this.handleRdpExpanded}>
              <Icon name="dropdown" />
              <b>Connecting via RDP (<i>Recommended</i>):</b>
            </Accordion.Title>
              <Accordion.Content active={rdpExpanded}>
                <List ordered>
                  <List.Item><span style={{ marginLeft: '6px' }}>Open your RDP client: (Or press the RDP button and proceed to step 3)</span>
                    <List Bulleted>
                      <List.Item><span style={{ marginLeft: '6px' }}>Windows (Built-in client): Press <b>Win+R</b>, type <code style={{ color: 'rgb(0, 0, 54)' }}>'mstsc'</code>, and press <b>Enter</b></span></List.Item>
                      <List.Item><span style={{ marginLeft: '6px' }}>Windows (New client): Open the 'Microsoft Remote Desktop' application</span></List.Item>
                      <List.Item><span style={{ marginLeft: '6px' }}>MacOS: Open the 'Windows App' application</span></List.Item>
                    </List>
                  </List.Item>
                  <List.Item><span style={{ marginLeft: '6px' }}>Select 'Add', then 'PCs'. (New client & Windows App only)</span></List.Item>
                  <List.Item><span style={{ marginLeft: '6px' }}>Enter the provided Hostname and Username into the client.</span></List.Item>
                  <List.Item><span style={{ marginLeft: '6px' }}></span>Save and connect. You will be prompted for the provided password.</List.Item>
                </List>
              </Accordion.Content>
            </Accordion>
            <Accordion className="mt2">
            <Accordion.Title active={dcvExpanded} index={0} onClick={this.handleDcvExpanded}>
              <Icon name="dropdown" />
              <b>Connecting via DCV:</b>
            </Accordion.Title>
              <Accordion.Content active={dcvExpanded}>
                <List ordered>
                  <List.Item><span style={{ marginLeft: '6px' }}>Open the Amazon DCV Client (or press the '<code style={{ color: 'rgb(0, 0, 54)' }}>Open DCV Client</code>' button below).</span></List.Item>
                  <List.Item><span style={{ marginLeft: '6px' }}>Enter the provided hostname into the client if you did not use the button above.</span></List.Item>
                  <List.Item><span style={{ marginLeft: '6px' }}>Enter the provided username and password into the client, and press 'Connect'.</span></List.Item>
                </List>
              </Accordion.Content>
            </Accordion>
            <div className="mt3">
              <i>Client Download Links:</i>
            </div>
            <List bulleted>
              <List.Item
                href="https://apps.microsoft.com/detail/9wzdncrfj3ps?ocid=webpdpshare"
                target="_blank"
                rel="noopener noreferrer"
              >
                Download Microsoft Remote Desktop for Windows
              </List.Item>
              <List.Item
                href="https://apps.apple.com/us/app/windows-app/id1295203466"
                target="_blank"
                rel="noopener noreferrer"
              >
                Download Microsoft Windows App for MacOS
              </List.Item>
              <List.Item
                href="https://www.amazondcv.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Download Amazon DCV Client for Windows/MacOS/Linux
              </List.Item>
            </List>
            {this.isAppStreamEnabled && (
              <div className="mt3">
                In your browser, please allow popups for this domain so we can open the AppStream page in a new tab for
                you
              </div>
            )}
          </Table.Cell>
        </Table.Row>

        {this.isAppStreamEnabled && windowsRdpInfo && (
          <Table.Row>
            <Table.Cell>
              <Button
                primary
                size="mini"
                onClick={this.handleAppStreamConnect(connectionId)}
                floated="right"
                disabled={this.processingId}
                loading={this.processingGetConnection}
                data-testid="connect-to-workspace-button"
              >
                Connect
              </Button>
            </Table.Cell>
          </Table.Row>
        )}
      </>
    );
  }

  renderPasswordLabel(password) {
    const showPassword = this.showPassword;
    return (
      <Label>
        Password
        <Label.Detail>{showPassword ? password : '****************'}</Label.Detail>
      </Label>
    );
  }

  renderUsernameLabel(username) {
    return (
      <Label>
        Username
        <Label.Detail>{username}</Label.Detail>
      </Label>
    );
  }

  renderHostLabel(network) {
    return (
      <Label>
        Hostname
        <Label.Detail>
          {network.value} <span className="fs-7 pl1">({network.info})</span>
        </Label.Detail>
      </Label>
    );
  }
}

// see https://medium.com/@mweststrate/mobx-4-better-simpler-faster-smaller-c1fbc08008da
decorate(ScEnvironmentRdpConnectionRow, {
  envsStore: computed,
  environment: computed,
  connections: computed,
  connection: computed,
  connectionId: computed,
  networkInterfaces: computed,
  processingId: observable,
  windowsRdpInfo: observable,
  processingGetInfo: observable,
  processingGetConnection: observable,
  showPassword: observable,
  rdpExpanded: observable,
  dcvExpanded: observable,
  handleGetInfo: action,
  handleDcvConnect: action,
  handleOpenDcv: action,
  handleOpenDcvClient: action,
  handleOpenDcvBrowser: action,
  toggleShowPassword: action,
});

export default inject('scEnvironmentsStore')(withRouter(observer(ScEnvironmentRdpConnectionRow)));
