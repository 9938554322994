/*
 *  Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 *
 *  Licensed under the Apache License, Version 2.0 (the "License").
 *  You may not use this file except in compliance with the License.
 *  A copy of the License is located at
 *
 *  http://aws.amazon.com/apache2.0
 *
 *  or in the "license" file accompanying this file. This file is distributed
 *  on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either
 *  express or implied. See the License for the specific language governing
 *  permissions and limitations under the License.
 */
/* eslint-disable no-await-in-loop */

import React from 'react';
import _ from 'lodash';
import { decorate } from 'mobx';
import { observer, inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { HorizontalBar } from 'react-chartjs-2';
import { Container, Header, Segment, Icon, List, Message } from 'semantic-ui-react';

import { displayError, displayWarning } from '@amzn/base-ui/dist/helpers/notification';
import ProgressPlaceHolder from '@amzn/base-ui/dist/parts/helpers/BasicProgressPlaceholder';

//import { getScEnvironments, getScEnvironmentCost, getUserProjectBudgets } from '../../helpers/api';
import { getUserProjectBudgets } from '../../helpers/api';
//import { API_LIMIT } from '../../models/environments-sc/ScEnvironmentsStore';
//import { blueDatasets } from './graphs/graph-options';
//import BarGraph from './graphs/BarGraph';

// News items to display in messages section
// This is a temporary solution to display news items on the dashboard.
// This will be replaced with a more robust solution using DynamoDB in the future.
// ------------------------------------------------------------------------------------------------
const newsItems = [
  {
    type: '*NEW*',
    content: 'All workspaces (excluding AL2023 and Notebooks) now support high-speed DCV connections.',
    color: 'green'
  },
  {
    type: '*NEW*',
    content: 'No more whitelisting required! Campus IPs are now automatically approved for workspace connections.',
    color: 'green'
  },
  {
    type: '*NOTE*',
    content: 'You will now automatically receive emails when you have expired workspaces that require cleaning up.',
    color: 'blue'
  }
];
// ------------------------------------------------------------------------------------------------

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      totalCost: 0,
      indexNameToTotalCost: {},
      indexNameToUserTotalCost: {},
      envIdToCostInfo: {},
      envIdToEnvMetadata: {},
      duplicateEnvNames: new Set(),
      isLoading: true,
    };
  }

  async componentDidMount() {
    window.scrollTo(0, 0);
    try {
      //const {
      //  totalCost,
      //  indexNameToTotalCost,
      //  indexNameToUserTotalCost,
      //  envIdToCostInfo,
      //  envIdToEnvMetadata,
      //  duplicateEnvNames,
      //} = await getCosts(getScEnvironmentsFn, getScEnvironmentCost);
      const budgets = await getUserProjectBudgets();
      this.setState({
        //totalCost,
        //indexNameToTotalCost,
        //indexNameToUserTotalCost,
        //envIdToCostInfo,
        //envIdToEnvMetadata,
        //duplicateEnvNames,
        isLoading: false,
        budgets,
      });
    } catch (error) {
      const store = this.getStore();

      // "AccessDeniedException" error code is thrown when Cost Explorer hasn't been configured
      if (error.code === 'AccessDeniedException') {
        if (store.user.isAdmin) {
          // Cost Explorer related errors are only to be shown to admins, not researchers (GALI-266)
          displayWarning(
            'Error encountered accessing cost data. Please enable Cost Explorer in the AWS Management Console and wait for 24 hours.',
          );
        }
      } else {
        displayError(error.message);
      }
    }
  }

  getStore() {
    return this.props.userStore;
  }

  render() {
    return (
      <Container className="mt3 mb4">
        {this.renderNewsTitle()}
        {this.renderNews()}
        {this.renderBudgetTitle()}
        {this.returnBudgetContent()}
      </Container>
    );
  }

  renderBudgetWarning(projectId) {
    return (
      <div key={projectId} style={{ marginBottom: '1.25em' }}>
        <Message
          negative
          className="mb4"
          icon="warning"
          header="Missing project budget"
          content={`The project ${projectId} does not have any AWS credits configured yet. Please contact RACE (race@rmit.edu.au) to setup your project.`}
        />
      </div>
    );
  }

  renderNewsTitle() {
    return (
      <div data-testid="page-title" className="mb2 flex">
        <Header as="h3" className="color-rmit-blue mt1 mb0 flex-auto">
          <Icon name="newspaper" className="align-top" />
          <Header.Content className="left-align">Announcements</Header.Content>
        </Header>
      </div>
    );
  }

  renderBudgetTitle() {
    return (
      <div data-testid="page-title" className="mb2 flex">
        <Header as="h3" className="color-rmit-blue mt1 mb0 flex-auto">
          <Icon name="dollar" className="align-top" />
          <Header.Content className="left-align">My Projects</Header.Content>
        </Header>
      </div>
    );
  }

//  renderCostTitle() {
//    return (
//      <div data-testid="page-title" className="mb2 flex">
//        <Header as="h3" className="color-rmit-blue mt1 mb0 flex-auto">
//          <Icon name="line graph" className="align-top" />
//          <Header.Content className="left-align">My Usage Summary</Header.Content>
//        </Header>
//      </div>
//    );
//  }

  renderNews() {
    return (
      <div className="mb3">
        {newsItems.length > 0 ? (
          <Segment>
            <List>
              {newsItems.map((item, index) => (
                <List.Item key={index} style={{ fontSize: '1.25em' }}>
                  <span style={{ color: item.color }}>{item.type}</span> - {item.content}
                </List.Item>
              ))}
            </List>
          </Segment>
        ) : (
          <Segment className="bold">There are no current announcements.</Segment>
        )}
      </div>
    );
  }

  returnBudgetContent() {
    return (
      <div className="mb3">
        {this.state.isLoading ? (
          <ProgressPlaceHolder />
        ) : _.isEmpty(this.state.budgets) ? (
          <Segment>You currently do not have an active project. Please contact RACE.</Segment>
        ) : (
          <>
            <Segment>{this.renderBudgetBarGraphs()}</Segment>
          </>
        )}
      </div>
    );
  }

//  renderCostContent() {
//    return (
//      <div className="mb3">
//        {this.state.isLoading === false && this.state.totalCost === 0 ? (
//          <Segment className="bold">No cost data to show</Segment>
//        ) : (
//          <>
//            <Segment className="bold" style={{ fontSize: '1.25em' }}>Total cost of all research workspaces for the past 30 days: US${(this.state.totalCost).toFixed(2)}</Segment>
//            <Segment>{this.renderPastMonthCostPerEnv()}</Segment>
//          </>
//        )}
//      </div>
//    );
//  }

  renderBudgetBarGraphs() {
    const { budgets } = this.state;
    const projectIds = Object.keys(budgets);
    
    // Sort the projectIds from highest to lowest budgetUsage
    projectIds.sort((a, b) => {
      const aUsage = parseFloat(budgets[a].budgetUsage / budgets[a].budgetLimit);
      const bUsage = parseFloat(budgets[b].budgetUsage / budgets[b].budgetLimit);
      return bUsage - aUsage;
    });

    return projectIds.map((projectId, pIndex) => {
      const { budgetUsage, budgetLimit, description } = budgets[projectId];

      // Missing Budget for project
      if (budgetLimit === -1 || budgetUsage === -1) {
        return this.renderBudgetWarning(projectId);
      }
      
      // Convert strings to numbers
      const budgetUsageValue = parseFloat(budgetUsage);
      const budgetLimitValue = parseFloat(budgetLimit);

      // Check if the values are numbers
      if (isNaN(budgetUsageValue) || isNaN(budgetLimitValue)) {
        console.log('Error: budgetUsage and budgetLimit must be numbers');
        return;
      }

      // Calculate the percentages
      const percentageUsed = (budgetUsageValue / budgetLimitValue) * 100;
      const percentageRemaining = 100 - percentageUsed;
      const usedValue = budgetUsageValue;
      const remainingValue = budgetLimitValue - budgetUsageValue;
  
      // Format the dollar values
      const formattedBudgetLimit = new Intl.NumberFormat().format(budgetLimitValue.toFixed(2));
      const formattedUsedValue = new Intl.NumberFormat().format(usedValue.toFixed(2));
      const formattedRemainingValue = new Intl.NumberFormat().format(remainingValue.toFixed(2));

      const data = {
        labels: [''],
        datasets: [
          {
            label: '% Consumed',
            data: [percentageUsed],
            backgroundColor: '#E61E2A',
            absoluteValues: [usedValue],
            barThickness: 30,
          },
          {
            label: '% Remaining',
            data: [percentageRemaining],
            backgroundColor: '#000054',
            absoluteValues: [remainingValue],
            barThickness: 30,
          },
        ],
      };
  
      const options = {
        maintainAspectRatio: false,
        scales: {
          xAxes: [{
            stacked: true,
            ticks: {
              min: 0,
              max: 100,
            },
            scaleLabel: {
              display: false,
              labelString: 'Percent Consumed'
            },
          }],
          yAxes: [{
            stacked: true,
            display: false,
          }],
        },
        tooltips: {
          enabled: false,
        },
        legend: {
          display: true,
        },
      };
      
      // Remove the whitespace for the last project in the list only
      const marginBottom = pIndex < projectIds.length - 1 ? '4em' : '0';

      return (
        <div key={projectId} style={{ marginBottom: '1em' }}>
          <h3>{projectId} ({description})</h3>
          <hr style={{ marginTop: '0.5em', marginBottom: '1em' }} />
          <div style={{ height: '100px', width: '100%', marginBottom: '1em' }}>
            <HorizontalBar data={data} options={options} />
          </div>
          <p style={{ fontSize: '1.25em', marginBottom: marginBottom }}>
            <span style={{ marginRight: '25px' }}>Budget Total: US${formattedBudgetLimit}</span>
            |
            <span style={{ marginLeft: '25px', marginRight: '25px' }}>Credits Consumed: US${formattedUsedValue} ({percentageUsed.toFixed(2)}%)</span>
            |
            <span style={{ marginLeft: '25px' }}>
              Credits Remaining: US${formattedRemainingValue} 
              (<span style={{ color: percentageRemaining <= 10 ? 'red' : 'inherit' }}>
                {percentageRemaining.toFixed(2)}%
              </span>)
            </span>
          </p>
        </div>
      );
    });
  }

//  renderPastMonthCostPerEnv() {
//    if (_.isEmpty(this.state.envIdToCostInfo)) {
//      return <ProgressPlaceHolder />;
//    }
//
//    const pastMonthCostTotalArray = [];
//    Object.keys(this.state.envIdToCostInfo).forEach(envId => {
//      const total = _.sum(_.values(this.state.envIdToCostInfo[envId].pastMonthCostByUser));
//      const totalFixed = parseFloat(total.toFixed(2));
//      pastMonthCostTotalArray.push(totalFixed);
//    });
//    const title = 'Instance Costs for Past 30 Days';
//    const labels = getLabels(this.state.envIdToCostInfo, this.state.envIdToEnvMetadata, this.state.duplicateEnvNames);
//    const dataPoints = pastMonthCostTotalArray;
//    const data = {
//      labels,
//      datasets: blueDatasets(title, dataPoints),
//    };
//
//    return <BarGraph className="mr4" data={data} title={title} />;
//  }
}

//async function getScEnvironmentsFn() {
//  const fields = 'id,name,indexId,updatedAt';
//
//  let offsetId;
//  let envs = [];
//  do {
//    const { result = [], offsetId: newOffsetId } = await getScEnvironments({ limit: API_LIMIT, offsetId, fields });
//
//    offsetId = newOffsetId;
//    envs = envs.concat(result);
//  } while (offsetId);
//  return envs;
//}

async function getCosts(getEnvironmentsFn, getEnvironmentCostFn) {
  const { envIdToCostInfo, envIdToEnvMetadata, duplicateEnvNames } = await getAccumulatedEnvCost(
    getEnvironmentsFn,
    getEnvironmentCostFn,
  );

  const indexNameToUserTotalCost = {};
  Object.keys(envIdToCostInfo).forEach(envId => {
    const indexName = envIdToEnvMetadata[envId].index;
    if (indexNameToUserTotalCost[indexName] === undefined) {
      indexNameToUserTotalCost[indexName] = {};
    }
    Object.keys(envIdToCostInfo[envId].pastMonthCostByUser).forEach(user => {
      const currentUserCost = _.get(indexNameToUserTotalCost[indexName], user, 0);
      indexNameToUserTotalCost[indexName][user] = currentUserCost + envIdToCostInfo[envId].pastMonthCostByUser[user];
    });
  });

  const indexNameToTotalCost = {};
  let totalCost = 0;
  Object.keys(indexNameToUserTotalCost).forEach(indexName => {
    let indexCost = 0;
    Object.keys(indexNameToUserTotalCost[indexName]).forEach(user => {
      indexCost += indexNameToUserTotalCost[indexName][user];
    });
    totalCost += indexCost;
    indexNameToTotalCost[indexName] = indexCost;
  });

  return {
    totalCost,
    indexNameToTotalCost,
    indexNameToUserTotalCost,
    envIdToCostInfo,
    envIdToEnvMetadata,
    duplicateEnvNames,
  };
}

function getLabels(envIdToCostInfo, envIdToEnvMetadata, duplicateEnvNames) {
  const labels = Object.keys(envIdToCostInfo).map(envId => {
    const envName = envIdToEnvMetadata[envId].name;
    if (duplicateEnvNames.has(envName)) {
      return `${envName}: ${envId}`;
    }
    return envName;
  });
  return labels;
}

async function getAccumulatedEnvCost(getEnvironmentsFn, getEnvironmentCostFn) {
  const environments = await getEnvironmentsFn();
  const duplicateEnvNames = new Set();
  const envNameToEnvId = {};
  const envIdToEnvMetadata = {};
  environments.forEach(env => {
    if (env.isExternal) return;
    envIdToEnvMetadata[env.id] = {
      index: env.indexId,
      name: env.name,
    };
    if (envNameToEnvId[env.name] === undefined) {
      envNameToEnvId[env.name] = env.id;
    } else {
      duplicateEnvNames.add(env.name);
    }
  });

  const envIds = Object.keys(envIdToEnvMetadata);
  const envCostPromises = envIds.map(envId => {
    return getEnvironmentCostFn(envId, 30, false, true);
  });

  const envCostResults = await Promise.all(envCostPromises);
  const pastMonthCostByUserArray = envCostResults.map(costResult => {
    const createdByToCost = {};
    _.forEach(costResult, costDate => {
      const cost = costDate.cost;
      Object.keys(cost).forEach(group => {
        let createdBy = group.split('$')[1];
        createdBy = createdBy || 'None';
        const currentUserCost = _.get(createdByToCost, createdBy, 0);
        createdByToCost[createdBy] = currentUserCost + cost[group].amount;
      });
    });
    return createdByToCost;
  });

  const yesterdayCostArray = envCostResults.map(costResult => {
    const yesterdayCost = costResult.length > 0 ? costResult[costResult.length - 1] : {};
    let totalCost = 0;
    if (yesterdayCost) {
      const arrayOfCosts = _.flatMapDeep(yesterdayCost.cost);
      arrayOfCosts.forEach(cost => {
        totalCost += cost.amount;
      });
    }
    return totalCost;
  });

  const envIdToCostInfo = {};
  for (let i = 0; i < envIds.length; i++) {
    envIdToCostInfo[envIds[i]] = {
      pastMonthCostByUser: pastMonthCostByUserArray[i],
      yesterdayCost: yesterdayCostArray[i],
    };
  }

  return { envIdToCostInfo, envIdToEnvMetadata, duplicateEnvNames };
}

// see https://medium.com/@mweststrate/mobx-4-better-simpler-faster-smaller-c1fbc08008da
decorate(Dashboard, {});

export default inject('userStore')(withRouter(observer(Dashboard)));
export { getAccumulatedEnvCost, getCosts, getLabels };
